export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL'

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL'

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST'
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS'
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CART_RESET = 'CART_RESET'

export const ORDER_REQUEST = 'ORDER_REQUEST'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const ORDER_FAIL = 'ORDER_FAIL'

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL'

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST'
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL'

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST'
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS'
export const UPDATE_ORDER_STATUS_FAIL = 'UPDATE_ORDER_STATUS_FAIL'

export const UPDATE_ORDER_DELIVERY_STATUS_REQUEST =
  'UPDATE_ORDER_DELIVERY_STATUS_REQUEST'
export const UPDATE_ORDER_DELIVERY_STATUS_SUCCESS =
  'UPDATE_ORDER_DELIVERY_STATUS_SUCCESS'
export const UPDATE_ORDER_DELIVERY_STATUS_FAIL =
  'UPDATE_ORDER_DELIVERY_STATUS_FAIL'

export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST'
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_FAIL = 'SUBSCRIBE_FAIL'

export const GET_SUBSCRIBER_LIST_REQUEST = 'GET_SUBSCRIBER_LIST_REQUEST'
export const GET_SUBSCRIBER_LIST_SUCCESS = 'GET_SUBSCRIBER_LIST_SUCCESS'
export const GET_SUBSCRIBER_LIST_FAIL = 'GET_SUBSCRIBER_LIST_FAIL'

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL'

export const FETCH_CANCELLED_ORDERS_REQUEST = 'FETCH_CANCELLED_ORDERS_REQUEST'
export const FETCH_CANCELLED_ORDERS_SUCCESS = 'FETCH_CANCELLED_ORDERS_SUCCESS'
export const FETCH_CANCELLED_ORDERS_FAIL = 'FETCH_CANCELLED_ORDERS_FAIL'

export const FETCH_CANCELLED_ORDER_REQUEST = 'FETCH_CANCELLED_ORDER_REQUEST'
export const FETCH_CANCELLED_ORDER_SUCCESS = 'FETCH_CANCELLED_ORDER_SUCCESS'
export const FETCH_CANCELLED_ORDER_FAIL = 'FETCH_CANCELLED_ORDER_FAIL'

export const ARCHIVE_ORDER_REQUEST = 'ARCHIVE_ORDER_REQUEST'
export const ARCHIVE_ORDER_SUCCESS = 'ARCHIVE_ORDER_SUCCESS'
export const ARCHIVE_ORDER_FAIL = 'ARCHIVE_ORDER_FAIL'

export const FETCH_ARCHIVED_ORDERS_REQUEST = 'FETCH_ARCHIVED_ORDERS_REQUEST'
export const FETCH_ARCHIVED_ORDERS_SUCCESS = 'FETCH_ARCHIVED_ORDERS_SUCCESS'
export const FETCH_ARCHIVED_ORDERS_FAIL = 'FETCH_ARCHIVED_ORDERS_FAIL'

export const FETCH_ARCHIVED_ORDER_REQUEST = 'FETCH_ARCHIVED_ORDER_REQUEST'
export const FETCH_ARCHIVED_ORDER_SUCCESS = 'FETCH_ARCHIVED_ORDER_SUCCESS'
export const FETCH_ARCHIVED_ORDER_FAIL = 'FETCH_ARCHIVED_ORDER_FAIL'

export const SET_LOADING = 'SET_LOADING'
